/*
font-family: 'Audiowide', cursive;
font-family: 'Roboto', sans-serif; 
*/

* {
  font-family: "Audiowide", cursive;
}

body {
  background: url("./assets/background.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

p,
a,
ul,
li,
label,
input,
textarea {
  font-family: "Roboto", sans-serif !important;
  color: white;
}

.btn-info {
  background-color: #dad0c0;
  border-color: #9f7f4e;
  color: #373939;
  width: 120px;
  height: 60px;
}

.btn-info:hover {
  background-color: #9f7f4e;
  border-color: #7a5b45;
  color: #dad0c0;
}

.btn,
.form-control {
  border-radius: 0rem;
}

#contact-message {
  height: calc(1.5em + 0.75rem + 75px);
}

.filler {
  margin-bottom: 100px;
  padding-bottom: 100px;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

.alert-info {
  background-color: #fae1fc !important;
  border-color: #fae1fc !important;
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em black;
  box-shadow: inset 0 0 0.5em 0 #fae1fc, 0 0 0.5em 0 #fae1fc;
}

.contact-link {
  color: #baf0fa;
}

.contact-link:hover {
  color: #baf0fa;
  text-decoration: underline;
  text-shadow: 0 0 0.25rem #baf0fa;
}

.pink {
  color: #fae1fc;
  text-decoration: underline;
  text-shadow: 0 0 0.25rem #fae1fc;
}

.neon-button {
  font-size: 1rem;
  display: inline-block;
  cursor: pointer;
  text-decoration: none !important;
  color: #baf0fa;
  background-color: black;
  border: #baf0fa 0.125em solid;
  padding: 0.25em 1.25em;
  border-radius: 0.25em;
  z-index: 1;

  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em #baf0fa;
  box-shadow: inset 0 0 0.5em 0 #baf0fa, 0 0 0.5em 0 #baf0fa;

  position: relative;
}

.neon-button::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 2em 0.5em #baf0fa;
    opacity: 0;
    background-color: #baf0fa;
    z-index: -1;
    transition: opacity 100ms linear;
}

.neon-button:hover,
.neon-button:focus {
    color: black;
    background-color: #baf0fa;
    text-shadow: none;
}

.neon-button:hover::after,
.neon-button:focus::after {
    opacity: 1;
}