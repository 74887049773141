.download-link {
  color: #fae1fc;
}

.download-link:hover {
  color: #fae1fc;
  text-shadow: 0 0 0.125rem #fae1fc;
}

.about {
  height: 31.25rem;
  width: 31.25rem;
}
