.space {
  padding-bottom: 9rem;
}

.neon-pink {
  color: #fae1fc;
  text-align: center;
  text-decoration: underline;
  text-shadow: 0 0 0.25rem #fae1fc;
}

.neon-blue {
  color: #baf0fa;
  text-align: center;
  text-decoration: underline;
  text-shadow: 0 0 0.25rem #baf0fa;
}
