.navbar {
  background-color: black;
}

.navbar-brand {
  font-weight: bold;
  color: #baf0fa !important;
}

.navbar-brand:hover {
  text-shadow: 0 0 0.125rem #baf0fa;
}

.nav-link {
  color: #fae1fc !important;
}

.nav-link:hover {
  color: #fae1fc;
  text-shadow: 0 0 0.125rem #fae1fc;
}

.hashlink {
  color: #fae1fc;
  padding: 0.5rem 0.5rem;
}

.hashlink:hover {
  color: #fae1fc;
  text-shadow: 0 0 0.125rem #fae1fc;
  text-decoration: none;
}

.resume-link {
  color: #baf0fa;
}

.resume-link:hover {
  color: #baf0fa;
  text-shadow: 0 0 0.125rem #baf0fa;
}

.resume-show {
  visibility: visible;
  text-decoration: underline;
  transition: visibility 0.5s ease;
}

.resume-hide {
  visibility: hidden;
  text-decoration: underline;
  transition: visibility 0.5s ease;
}
