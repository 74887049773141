.modal-header {
  background-color: black;
  color: #baf0fa;
  border-bottom: 1px solid #baf0fa;
  text-shadow: 0 0 0.25rem #baf0fa;
}

.modal-body {
  background-color: black;
  color: white;
  text-shadow: 0 0 0.25rem white;
}

.roboto {
  font-family: 'Roboto', sans-serif !important; 
}

.close-button {
  font-size: 1rem;
  font-family: 'Audiowide', cursive !important;
  display: inline-block;
  cursor: pointer;
  text-decoration: none !important;
  color: #fa7878;
  background-color: black;
  border: #fa7878 0.125em solid;
  padding: 0.25em 1.25em;
  border-radius: 0.25em;
  z-index: 1;

  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em #fa7878;
  box-shadow: inset 0 0 0.5em 0 #fa7878, 0 0 0.5em 0 #fa7878;

  position: relative;
}

.close-button::before {
    pointer-events: none;
    content: '';
    position: absolute;
    background: #fa7878;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;

    transform: perspective(1em) rotateX(40deg) scale(1, 0.35);
    filter: blur(1em);
    opacity: 0.7;
}

.close-button::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 2em 0.5em #fa7878;
    opacity: 0;
    background-color: #fa7878;
    z-index: -1;
    transition: opacity 100ms linear;
}

.close-button:hover,
.close-button:focus {
    color: black;
    text-shadow: none;
}

.close-button:hover::before,
.close-button:focus::before {
    opacity: 1;
}

.close-button:hover::after,
.close-button:focus::after {
    opacity: 1;
}

.github-button {
  font-size: 1rem;
  font-family: 'Audiowide', cursive !important;
  display: inline-block;
  cursor: pointer;
  text-decoration: none !important;
  color: #bbbbbb;
  background-color: black;
  border: #bbbbbb 0.125em solid;
  padding: 0.25em 1.25em;
  border-radius: 0.25em;
  z-index: 1;

  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em #bbbbbb;
  box-shadow: inset 0 0 0.5em 0 #bbbbbb, 0 0 0.5em 0 #bbbbbb;

  position: relative;
}

.github-button::before {
    pointer-events: none;
    content: '';
    position: absolute;
    background: #bbbbbb;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;

    transform: perspective(1em) rotateX(40deg) scale(1, 0.35);
    filter: blur(1em);
    opacity: 0.7;
}

.github-button::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 2em 0.5em #bbbbbb;
    opacity: 0;
    background-color: #bbbbbb;
    z-index: -1;
    transition: opacity 100ms linear;
}

.github-button:hover,
.github-button:focus {
    color: black;
    text-shadow: none;
}

.github-button:hover::before,
.github-button:focus::before {
    opacity: 1;
}

.github-button:hover::after,
.github-button:focus::after {
    opacity: 1;
}

.website-button {
  font-size: 1rem;
  font-family: 'Audiowide', cursive !important;
  display: inline-block;
  cursor: pointer;
  text-decoration: none !important;
  color: #3363ff;
  background-color: black;
  border: #3363ff 0.125em solid;
  padding: 0.25em 1.25em;
  border-radius: 0.25em;
  z-index: 1;

  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em #3363ff;
  box-shadow: inset 0 0 0.5em 0 #3363ff, 0 0 0.5em 0 #3363ff;

  position: relative;
}

.website-button::before {
    pointer-events: none;
    content: '';
    position: absolute;
    background: #3363ff;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;

    transform: perspective(1em) rotateX(40deg) scale(1, 0.35);
    filter: blur(1em);
    opacity: 0.7;
}

.website-button::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 2em 0.5em #3363ff;
    opacity: 0;
    background-color: #3363ff;
    z-index: -1;
    transition: opacity 100ms linear;
}

.website-button:hover,
.website-button:focus {
    color: black;
    text-shadow: none;
}

.website-button:hover::before,
.website-button:focus::before {
    opacity: 1;
}

.website-button:hover::after,
.website-button:focus::after {
    opacity: 1;
}