.skill {
  margin-bottom: auto;
}

.tech-title {
  color: #fae1fc;
  text-align: center;
  text-decoration: underline;
  text-shadow: 0 0 0.25rem #fae1fc;
}
