.card {
  box-shadow: 2px 1px 10px -5px black;
}

.card:hover {
  cursor: pointer;
  box-shadow: 2px 1px 13px -5px black;
}

.projects-label {
  color: #baf0fa;
  text-align: center;
  text-shadow: 0 0 0.25rem #baf0fa;
}
